<template>
  <v-expansion-panel-header>
    <div class="item-header d-flex">
      <span class="font-weight-bold">{{ product.displayName }} </span>
      <div class="d-flex align-center">
        <span
          ><span class="font-weight-bold">{{ $i18n.t("TXT_PRICES") }}</span> -
          <span v-if="saoJoaoPharmacy">
            Regular:
            <span class="font-weight-bold">{{
              formatMonetary(product.regularPrice)
            }}</span>
            |
          </span>
          {{ $i18n.t("TXT_ACTUAL") }}:
          <span class="font-weight-bold">{{
            formatMonetary(product.lastSalePrice)
          }}</span>
          |
          <span
            :class="{ aiPriceColor: product.isAIPrice && !product.isManual }"
          >
            {{ $i18n.t(optimizedPriceTranslationKey) }}:

            <span class="font-weight-bold">{{
              formatMonetary(
                priceToHeader ? priceToHeader : product.optimizedPrice
              )
            }}</span>
          </span>
          <Prd-tooltip
            v-if="product.isAIPrice && !product.isManual"
            :text="$t('TXT_NOT_APPLIED_RULE_ALERT')"
            :openOnTop="true"
          />

          |
          <span v-if="ABV">
            {{ $i18n.t("TXT_WHOLESALE_PRICE") }}:
            <span class="font-weight-bold">
              {{
                formatMonetary(product.wholesalePrice)
              }}
            </span>
            |
          </span>
          <span v-if="novaEra">{{ $t("TXT_IA_MARGIN") }}: </span>
          <span v-if="marest"> {{ $t("TXT_EXPECTED(EM)_MARGIN") }}: </span>
          <span v-if="!novaEra && !marest">{{ $i18n.t("TXT_MARGIN") }}: </span>
          <span :class="switchColors(product.optimizedMargin)">{{
            formatPercentage(
              marginToHeader ? marginToHeader : product.optimizedMargin
            )
          }}</span>
          <Tooltip-informations
            v-if="marest"
            class="pb-6 ml-3"
            :title="$t('TXT_EXPECTED(EM)_MARGIN_TOOLTIP')"
          />
          <span v-if="novaEra">
            |
            {{ $t("TXT_COMMERCIAL_MARGIN") }}:
            <span :class="switchColors(lastMargin)"
              >{{ formatPercentage(lastMargin) }}
            </span>
            <Tooltip-informations
              :title="$t('TXT_COMMERCIAL_MARGIN_TOOLTIP')"
            />
          </span>
          <span v-if="saoJoaoPharmacy">
            | {{ $t("TXT_SUGGESTED_DISCOUNT") }}:
            <span class="font-weight-bold">{{
              formatIdealDiscount(product.idealDiscount)
            }}</span>
            <Prd-tooltip
              v-if="product.idealDiscount < 0"
              :text="$t('TXT_IDEAL_DISCOUNT_NEGATIVE_TOOLTIP')"
              :openOnTop="true"
            />
          </span>
          | IC:
          <span :class="setICColor(product.competitivenessIndex)">{{
            ic
          }}</span>
          |
        </span>
        <Bold-cluster-informations-main
          v-if="bold"
          :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
          class="ml-1"
        />
      </div>
      <div class="rules-validation">
        <span
          ><Rule-path
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            :productName="product.displayName"
            :lastAction="product.lastAction"
          />
        </span>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";
import RulePath from "./components/rule-path/rule-path-main.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import BoldClusterInformationsMain from "./components/bold-cluster-informations/bold-cluster-informations-main.vue";
import PrdTooltip from "@/components/common/prd-tooltip.vue";
export default {
  props: ["product", "marginToHeader", "priceToHeader"],
  components: {
    RulePath,
    TooltipInformations,
    BoldClusterInformationsMain,
    PrdTooltip,
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    novaEra() {
      return this.idCompany == 3116;
    },

    marest() {
      return this.idCompany == 2617;
    },

    UPL() {
      return this.idCompany == 2858;
    },

    saoJoaoPharmacy() {
      return this.idCompany == 3238;
    },

    bold() {
      return this.idCompany == 3236;
    },

    ABV() {
      return this.idCompany == 3210;
    },

    ic() {
      return this.product?.competitivenessIndex
        ? formatPercentage(this.product.competitivenessIndex * 100)
        : "-";
    },
    optimizedPriceTranslationKey() {
      if (this.product.isManual) {
        return "TXT_MANUAL_PRICE";
      } else if (this.product.isAIPrice) {
        return "TXT_IA_PRICE_TITLE";
      } else {
        return "TXT_PRICES_OPTIMIZED";
      }
    },
  },
  methods: {
    formatMonetary,
    formatPercentage,
    formatIdealDiscount(value) {
      if (value == null) return "-";
      const discount = value * 100;
      return `${discount.toFixed(2).replace(".", ",")}%`;
    },
    switchColors(value, isInfo) {
      if (isInfo) return "neutral-value";

      if (value >= 0) return "positive-value";
      if (value < 0) return "negative-value";
    },

    setICColor(value) {
      if (!value) return "default-value";

      let valuePercentage = value * 100;

      if (valuePercentage >= 95 && valuePercentage <= 105)
        return "positive-value";
      else if (valuePercentage >= 85 && valuePercentage <= 115)
        return "warning-value";
      else return "negative-value";
    },
  },
};
</script>

<style lang="scss" scoped>
.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rules-validation {
    display: flex;
    align-items: center;
    color: $feedback-color-success-pure;
  }
}
.negative-value {
  color: $feedback-color-warning-pure !important;
  font-weight: bold;
}
.positive-value {
  color: $feedback-color-success-pure !important;
  font-weight: bold;
}
.neutral-value {
  color: $brand-color-primary-pure !important;
  font-weight: bold;
}
.warning-value {
  color: $feedback-color-helper-pure !important;
  font-weight: bold;
}
.default-value {
  color: black !important;
  font-weight: bold;
}
.negative-border {
  border-left: 6px solid $feedback-color-warning-pure !important;
}
.positive-border {
  border-left: 6px solid $feedback-color-success-pure !important;
}
.aiPriceColor {
  color: $feedback-color-helper-pure !important;
}
</style>
