<template>
  <div>
    <Prd-autocomplete-chip
      :originalValue="products"
      :title="$t('TXT_PRODUCTS')"
      :label="$t('TXT_SEARCH_2')"
      :items="productsItems"
      :itemText="'productName'"
      :placeholder="$t('TXT_SELECT_ONE_OR_MORE_PRODUCTS')"
      :externalDisabled="externalLoading"
      :externalLoading="externalLoading"
      @updates="products = $event"
      @hasSelections="$emit('handleChange')"
      class="mb-6"
    />

    <v-row no-gutters>
      <v-col
        v-for="filter in filters"
        :key="filter.valueType"
        :cols="colsSize"
        :class="filter.classes"
      >
        <Prd-combo-box
          v-model="filter.selected"
          :items="filter.items"
          :title="filter.label"
          :label="$t('TXT_SELECT')"
          :disabled="externalLoading"
          :loading="externalLoading"
          @hasSelections="$emit('handleChange')"
          @onFocus="$emit('toogleUnlockSeeResults')"
          @onBlur="$emit('toogleUnlockSeeResults')"
          multiple
          deletable-chips
          small-chips
          clearable
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdAutocompleteChip from "@/components/common/prd-autocomplete-chip.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  components: {
    PrdAutocompleteChip,
    PrdComboBox,
  },
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
    savedFilter: {
      type: Object,
    },
  },
  data() {
    return {
      products: [],
      productsItems: [],
      filters: [
        {
          label: this.$i18n.t("TXT_CATEGORY"),
          value: "categories",
          valueType: 1,
          items: [],
          classes: "pr-4",
          selected: [],
        },
        {
          label: this.$i18n.t("TXT_RETAILER"),
          value: "origins",
          valueType: 2,
          items: [],
          classes: "pr-4",
          selected: [],
        },
        {
          label: this.$i18n.t("TXT_MANUFACTURER"),
          value: "sellers",
          valueType: 10,
          items: [],
          classes: "pr-4",
          selected: [],
        },
        {
          label: this.$i18n.t("TXT_BRAND"),
          value: "brands",
          valueType: 3,
          items: [],
          classes: "",
          selected: [],
        },
      ],
    };
  },
  computed: {
    colsSize() {
      return 12 / this.filters.length;
    },
  },
  watch: {
    savedFilter: {
      handler(value) {
        this.populateFiltersBySavedFilter(value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cleanSelection() {
      this.products = [];
      this.filters.forEach((filter) => {
        filter.selected = [];
      });
    },
    getSelections() {
      let selections = {};
      selections.products = this.products;
      this.filters.forEach((filter) => {
        selections[filter.value] = filter.selected;
      });
      return selections;
    },
    populateFilters(items) {
      this.productsItems = items.products;
      this.filters.forEach((filter) => {
        filter.items = items[filter.value];
      });
    },
    populateAllProducts(allProducts) {
      this.productsItems = structuredClone(allProducts);
    },
    removeNonExistentSelectedItem(availableItems) {
      const keys = Object.keys(availableItems);

      keys.forEach((key) => {
        if (key == "products") {
          const itemsSelected = structuredClone(this.products);

          this.products = [];

          itemsSelected.forEach((item) => {
            const foundedItem = availableItems[key].find(
              (product) => product.idMonitoringItem == item.idMonitoringItem
            );
            if (foundedItem) this.products.push(foundedItem);
          });
        } else {
          const set = new Set(availableItems[key]);
          const filter = this.filters.find((filter) => filter.value == key);
          if (filter) {
            filter.selected = filter.selected.filter((item) => set.has(item));
          }
        }
      });
    },
    populateFiltersBySavedFilter(data) {
      if (!data) return;
      this.cleanSelection();

      if (data?.monitoringItems && data?.monitoringItems?.length > 0)
        this.products = this.productsItems.filter((product) =>
          data.monitoringItems.find(
            (item) => item.monitoringId == product.idMonitoringItem
          )
        );

      this.filters.forEach((currentFilter) => {
        const saved = data.values.filter(
          (save) => save.valueType == currentFilter.valueType
        );
        if (saved.length > 0) {
          currentFilter.selected = currentFilter.items.filter((item) =>
            saved.find((save) => save.value == item)
          );
        }
      });
    },
  },
};
</script>
