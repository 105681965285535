<template>
  <v-expansion-panel-content class="panel-main pt-2">
    <div class="prd-grid">
      <div class="grid-info">
        <Product-info
          :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
          class="info-product"
        />
        <Pricing-info
          :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
          @setMarginToHeader="$emit('setMarginToHeader')"
          class="prices-product"
          ref="pricing"
        />
      </div>
      <div class="grid-prices">
        <div class="prices">
          <Market-analysis
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            :productName="product.displayName"
            :projectionReference="projectionReference"
            class="market-analisis"
          />
          <Elasticity-info
            v-if="!UPL"
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            @setPriceToHeader="$emit('priceToHeader')"
            ref="elasticity"
            class="elasticity"
          />

          <Old-elasticity-info
            v-else
            :product="product"
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            @setPriceToHeader="$emit('priceToHeader')"
            ref="oldElasticity"
            class="elasticity"
          />
        </div>
        <div class="inputs-prices">
          <Manual-competitive-price
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            :pricingName="pricingName"
            @updatePricing="updatePricing"
            class="manual-competitive-price"
          />
          <Map-actions
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            :pricingName="pricingName"
            @updatePricing="updatePricing"
            ref="mapActions"
            class="actions"
          />
        </div>
      </div>
    </div>
  </v-expansion-panel-content>
</template>

<script>
import ElasticityInfo from "./components/elasticity-info.vue";
import ManualCompetitivePrice from "./components/manual-competitive-price.vue";
import MapActions from "./components/map-actions.vue";
import MarketAnalysis from "./components/market-analysis/market-analysis-main.vue";
import PricingInfo from "./components/pricing-info.vue";
import ProductInfo from "./components/product-info.vue";
import OldElasticityInfo from "./components/old-elasticity-info.vue";

export default {
  props: ["product", "pricingName"],
  components: {
    ProductInfo,
    PricingInfo,
    MarketAnalysis,
    ElasticityInfo,
    ManualCompetitivePrice,
    MapActions,
    OldElasticityInfo,
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    projectionReference() {
      return this.product?.projectionReference ?? null;
    },
    novaEra() {
      return this.idCompany == 3116 ? true : false;
    },
    marest() {
      return this.idCompany == 2617 ? true : false;
    },
    UPL() {
      return this.idCompany == 2858 ? true : false;
    },
    demoVarejo() {
      return this.idCompany == 3208 ? true : false;
    },
  },

  methods: {
    updatePricing(data) {
      if (!this.UPL) this.$refs["elasticity"].getElasticity(true);
      else this.$refs["oldElasticity"].getElasticity(true);

      this.$refs["pricing"].getPricingInfo(true);
      this.$refs["mapActions"].getWorkFlowState();

      data.values.forEach((item) => {
        if (item.displayName == this.product.displayName) {
          this.$emit("updatedSemaphore", item.semaphore);
          this.$store.commit("updatedSemaphore", data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-main {
  background-color: #f5f5f5;
}
.prd-grid {
  display: flex;
  gap: 0.5rem;
  height: 700px;

  .grid-info {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .info-product {
      height: 54%;
    }

    .prices-product {
      flex-grow: 1;
    }
  }

  .grid-prices {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .prices {
      height: 54%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .market-analisis {
        height: 40%;
      }

      .elasticity {
        flex-grow: 1;
      }
    }

    .inputs-prices {
      display: flex;
      gap: 0.5rem;
      flex-grow: 1;

      .manual-competitive-price {
        width: 80%;
      }

      .actions {
        width: 20%;
      }
    }
  }
}
</style>