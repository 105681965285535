<template>
  <v-card class="pa-4" id="currentPrice" flat>
    <v-row no-gutters align="center" justify="end" class="mb-4">
      <Prd-btn
        :title="'Exportar'"
        :icon="'mdi-download-outline'"
        :smallIcon="true"
        :leftIcon="true"
        :disabled="isLoading || !acelenList.length"
        @click="downloadData(acelenList, 'Preço_vigente')"
      />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="acelenList"
      :loading="isLoading"
      :footer-props="footerProps"
      :loading-text="'Carregando dados'"
      :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :items-per-page="10"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ momentFormatDate(item.date, false) }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ formatMonetary(item.price) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { downloadData } from "@/utils/downloadData.js";
import { momentFormatDate } from "@/utils/prd-format-date";
import { formatMonetary } from "@/utils/format-toMonetary";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import acelenService from "@/service/acelen/index";
const Service = new acelenService();

export default {
  components: { PrdBtn },

  data() {
    return {
      headers: [
        {
          text: "Data",
          align: "center",
          value: "date",
        },
        {
          text: "Combustivel",
          align: "center",
          value: "fuel",
        },
        {
          text: "Mobilidade",
          align: "center",
          value: "modality",
        },
        {
          text: "Local",
          align: "center",
          value: "local",
        },
        {
          text: "Valor vigente (R$)",
          align: "center",
          value: "price",
        },
      ],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },

      isLoading: false,
      acelenList: [],
    };
  },

  methods: {
    momentFormatDate,
    formatMonetary,
    downloadData,

    async getListAcelen() {
      this.$emit("setLoading", true);
      this.isLoading = true;

      try {
        let response = await Service.getListAcelen();
        this.acelenList = response?.data ?? [];
        this.acelenList.forEach((el) => {
          el.price = el.price / 1000
        })
        this.$emit("onListChange", this.acelenList);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar tabela de preço vigente");
      }

      this.isLoading = false;
      this.$emit("setLoading", false);
    },
  },

  created() {
    this.getListAcelen();
  },
};
</script>
