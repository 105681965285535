<template>
  <v-dialog v-model="isOpen" max-width="600">
    <v-card class="pa-4">
      <h2 class="mb-6">{{ $t("TXT_FAIL_WHEN_IMPORTING_MONITORING_ITEMS") }}</h2>
      <p>{{ $t("TXT_ERRORS_FOUND") }}:</p>
      <p class="red--text ma-0" v-for="error in errors" :key="error">
        {{ error }}.
      </p>
      <br />
      <p>{{ $t("TXT_TO_SEE_PATTERN_ALERT") }}</p>
      <v-row no-gutters justify="end" class="mt-6">
        <Prd-btn
          :outlined="true"
          :title="$t('TXT_CLOSE')"
          @click="isOpen = false"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: {
    PrdBtn,
  },
  data() {
    return {
      isOpen: false,
      errors: [],
    };
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) this.closeModal();
      },
    },
  },
  methods: {
    openModal(message) {
      this.isOpen = true;

      if (message && message.errors) {
        for (let error of message.errors) {
          let errorMessage = "";
          let translatedMessage;

          switch (error.textToken) {
            case "TXT_HEADER_MISMATCH_ERROR":
              translatedMessage = this.$i18n.t(error.textToken, {
                numberColumn: error.column,
                expected: error.expected,
                founded: error.found,
              });
              break;
            case "TXT_VALID_NUMBER_FOR_SKU":
              translatedMessage = this.$i18n.t(error.textToken, {
                field: this.$i18n.t(error.field),
                sku: error.sku,
              });
              break;
            default:
              translatedMessage = this.$i18n.t(error.textToken);
              break;
          }

          if (error.line > 0) {
            errorMessage = this.$i18n.t("TXT_ROW") + " " + error.line;
          }

          errorMessage +=
            (errorMessage.length > 0 ? ": " : "") + translatedMessage;

          this.errors.push(errorMessage);
        }
      } else {
        this.errors.push(message);
      }
    },
    closeModal() {
      this.isOpen = false;
      this.errors = [];
    },
  },
};
</script>
