<template>
  <v-container fluid>
    <PageTitle :title="pageTitle" :hasTooltip="false" />

    <div class="px-4" style="background-color: #fff">
      <v-tabs
        v-model="selectedTab"
        color="black"
        :slider-color="$prdStyles('color-secundary')"
        height="40px"
        class="border-destaq"
      >
        <v-tab class="pa-0"> ACELEN </v-tab>
        <v-tab :disabled="listLoading" class="pa-0"> SIMULAÇÃO </v-tab>
        <!-- <v-tab> PREDPLATTS </v-tab> -->
      </v-tabs>
    </div>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <Acelen-list-table
          @setLoading="listLoading = $event"
          @onListChange="acelenChart = $event"
          class="my-6"
        />

        <Acelen-chart :list="acelenChart" />
      </v-tab-item>

      <v-tab-item>
        <Acelen-simulation-table
          :acelenList="acelenChart"
          @onListChange="simulationChart = $event"
          class="my-6"
        />

        <Acelen-chart :list="simulationChart" />
      </v-tab-item>

      <v-tab-item v-if="false">
        <Pred-platts />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import PageTitle from "@/Design_System/common/title.vue";
import AcelenListTable from "./components/acelenListTable.vue";
import AcelenSimulationTable from "./components/acelenSimulationTable.vue";
import PredPlatts from "./components/predPlatts.vue";
import AcelenChart from "./components/charts/acelenChart.vue";
export default {
  components: {
    PageTitle,
    AcelenListTable,
    AcelenSimulationTable,
    PredPlatts,
    AcelenChart,
  },

  data() {
    return {
      selectedTab: 0,
      acelenChart: [],
      simulationChart: [],
      listLoading: false,
    };
  },

  computed: {
    pageTitle() {
      switch (this.selectedTab) {
        case 0:
          return "ACELEN";
        case 1:
          return "SIMULAÇÃO";
        case 2:
          return "PREDPLATTS";
        default:
          return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity-color {
  color: #00000099;
}

.border-destaq {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
}

.v-tab {
  font-size: 14px !important;
  text-transform: capitalize;
  font-weight: 600;
}
</style>
