import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class Acelen extends ServiceBase {
  async getListAcelen() {
    return await axiosInstance.get(API_URL + "api/v2/Petrobahia/GetListAcelen");
  }

  async getListAcelenSimulation() {
    return await axiosInstance.get(
      API_URL + "api/v2/Petrobahia/GetListAcelenSimulation"
    );
  }

  /**
   * @param {String} startDate  AAA-MM-DD
   * @param {String} endDate  AAA-MM-DD
   * @param {Number} idCompany
   */
  async getPredPlatts(startDate, endDate, idCompany) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Petrobahia/GetListPlatts?idCompany=${idCompany}&startDate=${startDate}&endDate=${endDate}`
    );
  }
}
